<template>
    <div>
      <!-- Burger Menu Icon (visible only on mobile) -->
      <button class="burger-menu-icon" @click="toggleMenu">
        <span class="material-symbols-rounded">
          {{ menuActive ? 'close' : 'menu' }} <!-- Change icon based on menuActive -->
        </span>
      </button>
  
      <!-- Overlay to hide the app view when menu is active -->
      <div v-if="menuActive" class="overlay" @click="closeMenu"></div>
  
      <!-- Sidebar Menu (only visible when menuActive is true) -->
      <aside :class="{ 'menu-active': menuActive }" class="sidebar">
        <nav class="menu">
          <div class="logo">
            <img :src="LogoNew" alt="Logo" />
          </div>
          <router-link to="/" class="menu-item flex items-center justify-center space-x-2" @click="closeMenu">
  <span class="material-symbols-rounded">home</span>
  <span>Home</span>
</router-link>
<router-link to="/alerts" class="menu-item flex items-center justify-center space-x-2" @click="closeMenu">
  <span class="material-symbols-rounded">notifications</span>
  <span>Alerts</span>
</router-link>
<router-link to="/masterdata" class="menu-item flex items-center justify-center space-x-2" @click="closeMenu">
  <span class="material-symbols-rounded">database</span>
  <span>CMS</span>
</router-link>
<router-link to="/instructions" class="menu-item flex items-center justify-center space-x-2" @click="closeMenu">
  <span class="material-symbols-rounded">library_books</span>
  <span>Instructions</span>
</router-link>
<router-link to="/logout" class="menu-item flex items-center justify-center space-x-2" @click="handleLogout">
  <span class="material-symbols-rounded">logout</span>
  <span>Logout</span>
</router-link>

        </nav>
      </aside>
    </div>
  </template>
  
  
  <script setup>
  import { ref } from 'vue';
  import { useRouter } from 'vue-router';
  import axios from 'axios';
  import LogoNew from '../assets/images/Logo2.png';


  
  const menuActive = ref(false);
  const router = useRouter();
  
  // Toggles the burger menu
  const toggleMenu = () => {
    menuActive.value = !menuActive.value;
  };
  
  // Closes the menu after a link is clicked
  const closeMenu = () => {
    menuActive.value = false;
  };
  
  // Handles user logout
  const handleLogout = async () => {
    try {
      await axios.post('/accounts/api/logout/');
      sessionStorage.removeItem('token');
      sessionStorage.removeItem('refresh_token');
      router.push('/login'); // Redirect to login page
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };
  </script>
  
  <style scoped lang="scss">
/* Styles for the burger menu icon */
.burger-menu-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 2rem;
  left: 1rem;
  z-index: 101;
  background: var(--dark);
  border: none;
  padding: 0.5rem;
  border-radius: 5px;
  cursor: pointer;

  .material-symbols-rounded {
    font-size: 2rem;
    color: var(--light);
  }
}

/* Sidebar styles */
.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background-color: var(--dark);
  overflow: hidden;
  transition: width 0.3s ease;
  z-index: 100;
}

/* Sidebar active state */
.sidebar.menu-active {
  width: 100vw;
}

/* Logo styles */
.logo {
  margin-top: 0rem;
  margin-bottom: 1rem;
  display: flex;
  justify-content: center; /* Centers content horizontally */
  align-items: center;     /* Centers content vertically (if needed) */

  img {
    width: 150px;
  }
}

/* Menu styles */
.menu {
  display: flex;
  flex-direction: column;
  padding: 2rem 1rem;
  opacity: 0; /* Initially hidden */
  visibility: hidden; /* Initially hidden */
  transform: translateY(20px); /* Start slightly below */
  transition: opacity 0.6s ease-out, visibility 0s 0.6s, transform 0.6s ease-out; /* Smooth transition for opacity, visibility, and position */
}

/* Apply animation only when the menu is active */
.sidebar.menu-active .menu {
  visibility: visible; /* Make it visible */
  opacity: 1; /* Fully opaque */
  transform: translateY(0); /* Reset the translate */
  animation: fadeInUp 0.6s ease-out forwards; /* Apply fade-in animation */
}

/* Keyframes for fading in from below */
@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px); /* Start slightly below */
  }
  100% {
    opacity: 1;
    transform: translateY(0); /* End in the original position */
  }
}

/* Menu item styles */
.menu-item {
  color: var(--light);
  text-decoration: none;
  margin: 0.8rem 0;
  font-size: 1.2rem;
  font-weight: bold;
  opacity: 0; /* Initially hidden */
  transform: translateY(20px); /* Start below */
  animation: fadeInUp 2s ease-out forwards; /* Apply fade-in animation */
  animation-delay: 2s; /* Initial delay for the first item */
  transition: transform 2s ease-out, opacity 2s ease-out; /* Ensure smooth transition */
}

/* Delay each subsequent menu item more */
.sidebar.menu-active .menu-item:nth-child(1) {
  animation-delay: 2s;
}

.sidebar.menu-active .menu-item:nth-child(2) {
  animation-delay: 2s;
}

.sidebar.menu-active .menu-item:nth-child(3) {
  animation-delay: 2s;
}

.sidebar.menu-active .menu-item:nth-child(4) {
  animation-delay: 2s;
}

.sidebar.menu-active .menu-item:nth-child(5) {
  animation-delay: 2s;
}

/* Overlay to hide the app view when menu is open */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
  z-index: 8;
}

/* Close menu on larger screens */
@media (min-width: 940px) {
  .burger-menu-icon {
    display: none;
  }

  .sidebar {
    display: none;
  }
}





  </style>
  